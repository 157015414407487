import React from "react";
import Layout from "../components/Layout";

import "../styles/terms.less";

const TermsPage: React.FC = (props) => {
  return (
    <Layout>
      <main>Terms</main>
    </Layout>
  );
};

export default TermsPage;
